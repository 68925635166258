import { EComponentTypenames, ISysCfl } from "@shared/contentful/contentful.types";
import { IAssetCflData } from "../ComponentAsset/type";
import { ILinkCflData } from "../ComponentLink/type";

export enum EAssetWithTextCflDataLayoutType {
	CardWithImage = "card",
	MediaTextDescription = "mosaic",
}
export interface IAssetWithTextCflData extends ISysCfl {
	__typename: EComponentTypenames;
	identifier?: string;
	layoutType?: EAssetWithTextCflDataLayoutType;
	cardLink?: ILinkCflData;
	asset?: IAssetCflData;
	infoText?: string;
	title?: string;
	subtitle?: string;
	text?: string;
}
