import React from "react";
import { EAssetWithTextCflDataLayoutType, IAssetWithTextCflData } from "./type";
import CardWithImage from "@components/ContentfulComponents/ComponentAssetWithText/CardWithImage/CardWithImage.component";
import MediaTextDescription from "@components/ContentfulComponents/ComponentAssetWithText/MediaTextDescription/MediaTextDescription.component";
import { EContentPagesContentBlockCflLayout } from "../ContentPagesContentBlock/type";

interface IProps {
	data: IAssetWithTextCflData;
	absoluteUrl?: string;
	layout?: EContentPagesContentBlockCflLayout;
	loading?: boolean;
}

const AssetWithText = ({ data, absoluteUrl, layout, loading }: IProps) => {
	if (!data) return null;

	const { asset, layoutType, infoText, subtitle, title, cardLink, text } = data;

	switch (layoutType) {
		case EAssetWithTextCflDataLayoutType.CardWithImage:
			return (
				<CardWithImage
					asset={asset}
					cardLink={cardLink}
					title={title}
					subtitle={subtitle}
					infoText={infoText}
					absoluteUrl={absoluteUrl}
					loading={loading}
				/>
			);

		case EAssetWithTextCflDataLayoutType.MediaTextDescription:
			return (
				<MediaTextDescription
					text={text}
					subtitle={subtitle}
					title={title}
					asset={asset}
					layout={layout}
				/>
			);
		default:
			return null;
	}
};

export default AssetWithText;
