import React from "react";

import AssetWithText from "@components/ContentfulComponents/ComponentAssetWithText/AssetWithText.component";
import { IAssetWithTextCflData } from "@components/ContentfulComponents/ComponentAssetWithText/type";
import { isAssetWithText } from "@shared/contentful/contentful.utils";
import { normalizeLayout } from "@components/ContentfulComponents/ContentPagesContentBlock/utils";
import { EContentPagesContentBlockCflLayout } from "@components/ContentfulComponents/ContentPagesContentBlock/type";

import styles from "./card-and-image-block.module.scss";

interface IProps {
	data: IAssetWithTextCflData[];
	layout?: EContentPagesContentBlockCflLayout;
	absoluteUrl?: string;
	loading?: boolean;
}

const CardAndImageBlock = ({ data, layout, absoluteUrl, loading }: IProps) => {
	if (!data || data.length === 0) return null;

	const normalizedLayout = normalizeLayout(layout);

	return (
		<div className={`${styles.wrapper} ${normalizedLayout ? styles[normalizedLayout] : ""}`}>
			{data.map((item) => {
				if (!item || !isAssetWithText(item)) return null;
				return (
					<AssetWithText
						data={item}
						key={item.sys?.id}
						layout={layout}
						absoluteUrl={absoluteUrl}
						loading={loading}
					/>
				);
			})}
		</div>
	);
};

export default CardAndImageBlock;
