import { useRelOptionsFollowHome } from "@hooks/useFollowHome";
import { convertTargetOptions } from "@shared/contentful/contentful.utils";
import CardContent from "./CardContent.component";
import { removeLeadingSlash } from "@utils/url.utils";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";

interface ICardWithImage {
	cardLink?: ILinkCflData;
	asset?: IAssetCflData;
	infoText?: string;
	title?: string;
	subtitle?: string;
	absoluteUrl?: string;
	loading?: boolean;
}

const CardWithImage = ({
	asset,
	cardLink,
	title,
	subtitle,
	infoText,
	absoluteUrl,
	loading,
}: ICardWithImage) => {
	if (absoluteUrl && cardLink) {
		const { url: linkUrl, relOptions, targetOptions } = cardLink;
		const rel = useRelOptionsFollowHome(relOptions);
		const target = convertTargetOptions(targetOptions);

		return (
			<a
				href={`${absoluteUrl}/${removeLeadingSlash(linkUrl)}`}
				rel={rel}
				target={target}
				data-testid="CardWithImage_link"
			>
				<CardContent
					asset={asset}
					title={title}
					subtitle={subtitle}
					specialText={infoText}
					loading={loading}
				/>
			</a>
		);
	}

	return (
		<CardContent
			asset={asset}
			title={title}
			subtitle={subtitle}
			specialText={infoText}
			loading={loading}
		/>
	);
};

export default CardWithImage;
