import React from "react";

import { EImageSlot } from "@components/CustomImage/custom-images.types";
import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import Asset from "@components/ContentfulComponents/ComponentAsset/Asset.component";
import { replaceNonBreakingSymbol } from "@shared/text/text.utils";

import styles from "./CardContent.module.scss";

export interface ICardContent {
	asset?: IAssetCflData | null;
	title?: string;
	subtitle?: string;
	specialText?: string;
	loading?: boolean;
}

const CardContent = ({ asset, specialText, title, subtitle, loading }: ICardContent) => {
	if (!asset) return null;

	return (
		<div>
			<div className={styles.imageWrapper} data-testid="CardContent_image-wrapper">
				<Asset
					data={asset}
					imageSlot={{ desktop: EImageSlot.Third, mobile: "" }}
					loading={loading}
				/>
			</div>
			{specialText && (
				<p className={styles.specialText} data-testid="CardContent_special-text">
					{replaceNonBreakingSymbol(specialText)}
				</p>
			)}
			{title && (
				<p className={styles.title} data-testid="CardContent_title">
					{replaceNonBreakingSymbol(title)}
				</p>
			)}
			{subtitle && (
				<p className={styles.subtitle} data-testid="CardContent_subtitle">
					{replaceNonBreakingSymbol(subtitle)}
				</p>
			)}
		</div>
	);
};

export default CardContent;
