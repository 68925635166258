import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import Asset from "@components/ContentfulComponents/ComponentAsset/Asset.component";
import { EContentPagesContentBlockCflLayout } from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { normalizeLayout } from "@components/ContentfulComponents/ContentPagesContentBlock/utils";
import { replaceNonBreakingSymbol } from "@shared/text/text.utils";

import styles from "../asset-with-text.module.scss";

interface IMediaTextDescriptionProps {
	layout?: EContentPagesContentBlockCflLayout;
	asset?: IAssetCflData;
	title?: string;
	subtitle?: string;
	text?: string;
}

const MediaTextDescription = ({
	text,
	subtitle,
	title,
	asset,
	layout,
}: IMediaTextDescriptionProps) => {
	if (!asset || !title) {
		return null;
	}

	const normalizedLayout = normalizeLayout(layout);

	return (
		<div
			className={` ${normalizedLayout ? styles[normalizedLayout] : ""} ${
				styles.mediaTextDescriptionWrapper
			}`}
			data-testid="MediaTextDescription_wrapper"
		>
			<Asset className={styles.assetWrapper} data={asset} loading />
			<p className={styles.mediaTextDescriptionTitle} data-testid="MediaTextDescription_title">
				{replaceNonBreakingSymbol(title)}
			</p>
			{subtitle && (
				<p
					className={styles.mediaTextDescriptionSubtitle}
					data-testid="MediaTextDescription_subtitle"
				>
					{replaceNonBreakingSymbol(subtitle)}
				</p>
			)}
			{text && (
				<p className={styles.mediaTextDescriptionParagraph} data-testid="MediaTextDescription_text">
					{replaceNonBreakingSymbol(text)}
				</p>
			)}
		</div>
	);
};

export default MediaTextDescription;
